import React from 'react';
import { inject, observer } from 'mobx-react';
import cookie from 'react-cookie';
import { Form, Divider, Popup, Icon } from 'semantic-ui-react';
import gql from 'graphql-tag';
import { Link } from 'react-router';

import Input from '../../components/ui/Input';
import PhoneInput from '../../components/ui/PhoneInput';
import Button from '../../components/ui/Button';

import { __ } from '../../i18n';
import parse from '../../lib/parse';
import * as utils from '../../utils';

@inject('store', 'api', 'client') @observer
export default class SignUp extends React.Component {
  static fetchData({ store }) {
    store.app.title = __('Sign Up');
  }

  constructor(props) {
    super(props);

    this.state = {
      fullname: '',
      email: '',
      phone: '',
      type: 'email',
      error: [],
      validatePhone: false,
      validateEmail: false,
      fullnameFocused: true,
      disabled: true
    };
  }

  componentDidMount() {
    const { user } = this.props.store.auth;

    if (!user) return this.props.router.push('/auth');

    const type = user && user.type === 'email' ? 'phone' : 'email';
    this.setState({ type });
  }

  componentDidUpdate(prevProps, prevState) {
    const { type, email, phone } = this.state;

    const newValidatePhone = parse.validatePhone('', phone);
    const newValidateEmail = parse.validateEmail(email);

    if (type === 'email' && prevState.validateEmail !== newValidateEmail) {
      this.setState({ validateEmail: newValidateEmail });
    }

    if (type === 'phone' && prevState.validatePhone !== newValidatePhone) {
      this.setState({ validatePhone: newValidatePhone });
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.validate();
  }

  validate = () => {
    const { type, email, phone } = this.state;

    const valid = type === 'email' ?
      ((email.length > 0 && parse.validateEmail(email)) || email.length === 0) :
      ((phone.length > 0 && parse.validatePhone('', phone)) || phone.length === 0);

    // Show message error
    if (!valid && type === 'email') this.props.store.snackbar = { active: true, message: __('Email is not valid'), success: false };
    if (!valid && type === 'phone') this.props.store.snackbar = { active: true, message: __('Phone number is not valid'), success: false };

    if (valid) this.submit();
  }

  submit = async () => {
    const { store, router, client } = this.props;
    const { type, fullname } = this.state;
    const params = {
      language: 'pt'
    };

    if (type === 'email') {
      params.email = this.state.email;
      params.phone = store.auth.user.address;
    } else {
      params.phone = this.state.phone.replace(/[^0-9]+/g, '');
      params.email = store.auth.user.address;
    }

    if (typeof window !== 'undefined' && window.navigator && window.navigator.language) {
      params.language = window.navigator.language.substring(0, 2) === 'en' ? 'en' : 'pt';
    }

    if (this.state[type]) {
      try {
        const data = await client.mutate({
          mutation: gql`mutation findUser($email: String, $phone: String) {
          findUser(email: $email, phone: $phone, source: WEB) {
            ... on User {
              id: dbId
            }
          }
        }`,
          variables: {
            [type]: params[type]
          },
          options: {
            fetchPolicy: 'network-only'
          }
        });
        const { id } = data.data.findUser;

        if (id) {
          this.props.store.snackbar = { active: true, message: __(`${type === 'email' ? 'Email' : 'Phone'} already in use`), success: false };
          return;
        }
      } catch (err) {
        if (err.networkError) {
          this.props.store.snackbar = { active: true, message: __('No connection available'), success: false };
          return;
        }
      }
    }

    store.auth.user = { ...store.auth.user, ...params, fullname, methodType: 'signup' };
    router.push('/auth/createPassword' + this.props.location.search);
  };

  render() {
    const { email, phone, fullname, type, loading, validatePhone, validateEmail, fullnameFocused, disabled } = this.state;

    return (
      <div id="AuthSignUp" >
        <h1 className="label" style={{ fontSize: '28px', textAlign: 'left' }}>
          {__('Now we need some information about you 😁')}
        </h1>
        <Divider hidden />
        <Form>
          <div>
            <p style={{ textAlign: 'left', padding: '5px 0px', margin: '0px' }}>
              {__('What is your name?')}
              <Popup
                trigger={<Icon name="info circle" style={{ marginLeft: '0px', color: '#999999' }} />}
                content={__('Enter your own full name, not the name of the student you are representing')}
              />
            </p>
            <Input
              name="fullname"
              placeholder={__('Enter your full name')}
              red={!fullnameFocused && fullname.length === 0}
              onKeyPress={e => e.key === 'Enter' && fullname.length !== 0 && this.handleSubmit(e)}
              onBlur={() => this.setState({ fullnameFocused: false })}
              onFocus={() => this.setState({ fullnameFocused: true })}
              error={!fullnameFocused && fullname.length == 0 && __('Required field')}
              inputStyle={{ borderRadius: '8px', fontSize: '16px' }}
              onChange={({ target: { value } }) => this.setState({ fullname: value.trim(), disabled: value.trim().length === 0 })}
            />
          </div>
          <Divider hidden />
          <div style={{ marginBottom: '36px' }}>
            <p style={{ textAlign: 'left', padding: '5px 0px', margin: '0px' }}>
              {__('What is your %s?', type === 'phone' ? __('phone') : __('email'))}
              <span style={{ color: '#999999' }}> {__('(Optional)')}</span>
            </p>
            {
              type === 'phone' ?
                <PhoneInput
                  name="phone"
                  newStyleEnabled
                  placeholder={__('DDD + Phone')}
                  red={!validatePhone && phone.length > 0}
                  inputStyle={{ borderRadius: '0px 8px 8px 0px', borderLeft: 'none', fontSize: '16px' }}
                  onKeyPress={e => e.key === 'Enter' && fullname.length !== 0 && this.handleSubmit(e)}
                  onChange={value => this.setState({ phone: value || '' })}
                />
                :
                <Input
                  name="email"
                  placeholder={__('E-mail (Optional)')}
                  red={!validateEmail && email.length > 0}
                  onKeyPress={e => e.key === 'Enter' && fullname.length !== 0 && this.handleSubmit(e)}
                  inputStyle={{ borderRadius: '8px', fontSize: '16px' }}
                  onChange={({ target: { value } }) => this.setState({ email: value })}
                />
            }
          </div>
          <Button primary full round text={__('Continue')} style={{ padding: '16px 24px' }} onClick={this.handleSubmit} loading={loading} disabled={loading || disabled} />
        </Form>
        {this.props.children}
      </div>
    );
  }
}
