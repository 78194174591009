import React from 'react';
import { inject, observer } from 'mobx-react';
import { Container, Grid, Sidebar, Label, Image, Icon, Button } from 'semantic-ui-react';
import Steps from '../../components/Steps';

import Snackbar from '../../components/Snackbar';
import NoJavascript from '../../components/NoJavascript';
import Responsive from '../../components/Responsive';

import * as utils from '../../utils';
import { __ } from '../../i18n';

const styles = {
  icon: {
    margin: 'auto',
    fontSize: '1em',
    color: 'white',
  },
  label: {
    color: '#0069FF', //'#228be6',
    fontWeight: 'bold'
  },
};

@inject('store') @observer
export default class AuthNode extends Responsive {
  static fetchData({ store }) {
    store.app.title = __('Auth');
  }

  renderAppends = () => this.props.store.appends.map((append, i) =>
    <div key={i}>{append}</div>
  )

  constructor(props) {
    super(props);

    this.state = {
      pageIndex: this.pageIndex(this.props.location.pathname),
    };
  }

  componentWillReceiveProps(nextProps) {
    const { pathname } = nextProps.location;
    if (pathname !== this.props.location.pathname) {
      this.setState({
        pageIndex: this.pageIndex(pathname)
      });
    }
  }

  pageIndex = (pathName) => {
    const paths = pathName.split('/').filter(path => path !== '');
    const current = paths[paths.length - 1];
    const previous = this.props.location.pathname;

    if (current === 'code') return 0;
    if (current === 'signup') return 1;
    if (current === 'createPassword' && previous === '/auth/signup') return 2;

    return false;
  }

  renderSteps = () => {
    const { pageIndex } = this.state;

    return (
      <Steps
        name="signup-steps"
        items={
          [{
            icon: 1
          }, {
            icon: 2,
          }, {
            icon: 3
          },
          ]}
        checkedIcon={<Icon style={styles.icon} name="check" />}
        style={{ maxWidth: '460px', marginBottom: '42px', textAlign: 'center', fontSize: '1.14285714rem' }}
        activeItem={pageIndex}
        color="#0069FF"
        size={2.5714}
        spacing={6.5}
      />);
  }


  renderStepsMobile = () => {
    const { pageIndex } = this.state;

    const steps = [
      <p style={styles.label}>{__('STEP %s OF %s : ', 1, 3)}<span>{__('Validate code').toUpperCase()}</span></p>,
      <p style={styles.label}>{__('STEP %s OF %s : ', 2, 3)}<span>{__('Sign Up').toUpperCase()}</span></p>,
      <p style={styles.label}>{__('STEP %s OF %s : ', 3, 3)}<span>{__('Create Password').toUpperCase()}</span></p>,
    ];

    return (
      pageIndex === false ? null : steps[pageIndex]
    );
  }

  render() {
    const { app } = this.props.store;
    const { pageIndex } = this.state;
    return (
      <Sidebar.Pushable id="AuthNode" style={{ backgroundColor: '#FFF' }}>
        <Grid style={{ padding: '28px' }}>
          <Grid.Row>
            <Grid.Column>
              <Container>
                <Image href="https://www.classapp.com.br/" src={utils.asset('/logo/ClassApp-LOGO-SIMBOLO_azul.png')} size="mini" centered className="logo" />
                {
                  app.env !== 'production' && app.env !== 'beta' ?
                    <div style={{ right: '10px', position: 'absolute' }}>
                      <Label color={utils.envColor(app.env)} as="a" href={app.url}>{app.env}</Label>
                    </div> : null
                }
              </Container>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <NoJavascript />
        <Sidebar animation="overlay" width="wide" visible={false} />
        <Sidebar.Pusher className={this.isMobile() ? 'md' : ''}>
          <Container>
            <Grid
              stackable
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Grid.Column style={{ maxWidth: '370px', padding: '14px 0 14px 0' }}>
                <div style={{ textAlign: 'center' }}>
                  {pageIndex !== false &&
                  <div style={{ width: '100%', marginBottom: 16 }}>
                    {this.isMobile() ? this.renderStepsMobile() : this.renderSteps()}
                  </div>
                  }
                  {this.props.children}
                </div>
              </Grid.Column>
            </Grid>
          </Container>
          <Button
            as="a"
            href="https://ajuda.classapp.com.br/hc/pt-br"
            target="_blank"
            rel="noreferrer noopener"
            size="large"
            className="helpButton"
          >
            <Icon size="large" name="question circle" />
            {__('Help')}
          </Button>
        </Sidebar.Pusher>
        <Snackbar />
        {this.renderAppends()}
      </Sidebar.Pushable>
    );
  }
}
