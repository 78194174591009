import React from 'react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { inject, observer } from 'mobx-react';
import { Button, Loader, Dimmer, Image, Popup, Icon, Dropdown, Message, Grid } from 'semantic-ui-react';
import { Link } from 'react-router';

import Avatar from '../../components/Avatar';
import ColoredCheckbox from '../../components/ColoredCheckbox';


import { __ } from '../../i18n';
import * as utils from '../../utils';

const styles = {
  loading: {
    margin: 'auto',
  },
  logo: {
    width: '59.9px',
    display: 'initial',
    marginBottom: '48px',
  },
  box: {
    width: '100%',
    height: '100%',
    padding: '120px',
    textAlign: 'center',
  },
  header: {
    width: '500px',
    margin: 'auto',
    marginBottom: '48px',
    fontSize: '24px',
    color: '#212529',
  },
  buttonSubmit: {
    width: '320px',
    height: '48px',
    color: 'white',
    borderRadius: '4px',
    backgroundColor: '#0080ff',
    marginBottom: '8px',
  },
  buttonRejection: {
    width: '320px',
    height: '48px',
    borderRadius: ' 4px',
    backgroundColor: '#ffffff',
    border: 'solid 1px #e9ecef',
  },
  popup: {
    width: '16px',
    height: '16px',
    backgroundColor: '#ced4da',
  },
  observation: {
    marginTop: '88px',
    color: '#868e96',
    fontSize: '14px',
    width: '500px',
    height: '36px',
  },
  dropdown: {
    width: '320px',
  },
  noPermission: {
    width: '500px',
    margin: 'auto',
    marginTop: '48px',
  },
};

@inject('store', 'client')
@graphql(gql`query ApplicationNodeQuery ($clientId: String!) {
    application(clientId: $clientId) {
      id: dbId
      name
      description
      author
      scope
      entityScope
      term{
        links
      }
      organization{
      nodes{
        organizationId
      }
    }
    }
    viewer {
      id: dbId
      fullname
      phone
      email
      confirmPhone
      confirmEmail
      organizations (limit: 40, orderBy: { column: CREATED, direction: ASC}) {
        nodes {
          id: dbId
          fullname
          logo {
            uri
          }
          myEntities (type:ADMIN){
            totalCount
          }
        }
      }
      oauthProviders {
        nodes {
          application {
            name
            clientId
          }
        }
      }
    }
  }
`, {
  options: ownProps => ({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
      clientId: ownProps.location.query.client_id
    }
  })
})

@observer
export default class ThirdParty extends React.Component {
  constructor(props) {
    super(props);

    const organization_id = props.location.query.organization_id && parseInt(props.location.query.organization_id, 10);

    this.state = {
      loading: true,
      disabled: !organization_id,
      organization: organization_id || null
    };
  }

  componentDidMount() {
    this.setState({ loading: false });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.loaded && nextProps.data && nextProps.data.application && nextProps.data.viewer && !nextProps.data.loading) {
      this.loaded = true;

      const { application, viewer } = nextProps.data;

      if (application.scope !== null) return;
      if (viewer.oauthProviders.nodes.find(oauthProvider => oauthProvider.application.clientId === nextProps.location.query.client_id)) {
        this.setState({ loading: true });

        this.authorizeApplication();
      }
    }
  }

  onOrganizationChange = (value) => {
    this.setState({ organization: value, disabled: false });
  }

  handleRejection = (e) => {
    e.preventDefault();

    utils.redirectUrl(this.props.location.query.redirect_uri + '?error=access_denied' +
      '&error_description=the+user+canceled+the+authentication');
  }

  handleSubmit = (e) => {
    e.preventDefault();

    this.setState({ loading: true });

    this.submit();
  }

  authorizeMarketplace = () => {
    const { store, client, location } = this.props;
    const { organization } = this.state;
    const { client_id, redirect_uri } = location.query;

    let redirectParams = redirect_uri.includes('?') ? '&' : '?';

    Object.keys(location.query)
      .forEach((k) => {
        if (!['client_id', 'redirect_uri'].includes(k)) {
          redirectParams += k + '=' + location.query[k] + '&';
        }
      });

    client.mutate({
      mutation: gql`mutation authorizeMarketplace($clientId: String!, $redirectUri: String!, $organizationId: ID!) {
        authorizeMarketplace(input:{
          clientId: $clientId,
          redirectUri: $redirectUri,
          organizationId: $organizationId,
        }){
          organizationId
          entityId
          userId
        }
      }`,
      variables: {
        clientId: client_id,
        redirectUri: redirect_uri,
        organizationId: organization,
      }
    }).then((result) => {
      const { entityId, userId, organizationId } = result.data.authorizeMarketplace;
      utils.redirectUrl(redirect_uri + redirectParams + 'entityId=' + entityId
        + '&userId=' + userId + '&organizationId=' + organizationId);
    }).catch((err) => {
      store.snackbar = { active: true, message: utils.handleError(err.graphQLErrors[0]), success: false };
      utils.redirectUrl(redirect_uri + '?error=authorization_error' +
        '&error_description=' + encodeURIComponent(err.graphQLErrors[0].message));
    })
  }

  authorizeApplication = () => {
    const { store, client, location } = this.props;
    const { client_id, redirect_uri } = location.query;

    let redirectParams = redirect_uri.includes('?') ? '&' : '?';

    Object.keys(location.query)
      .forEach((k) => {
        if (!['client_id', 'redirect_uri'].includes(k)) {
          redirectParams += k + '=' + location.query[k] + '&';
        }
      });

    client.mutate({
      mutation: gql`mutation authorizeApplication($clientId: String!, $redirectUri: String!) {
        authorizeApplication(input: { clientId: $clientId, redirectUri: $redirectUri }) {
          code
        }
      }`,
      variables: {
        clientId: client_id,
        redirectUri: redirect_uri,
      }
    }).then((result) => {
      const { code } = result.data.authorizeApplication;
      utils.redirectUrl(redirect_uri + redirectParams + 'code=' + code);
    }).catch((err) => {
      store.snackbar = { active: true, message: utils.handleError(err.graphQLErrors[0]), success: false };
      utils.redirectUrl(redirect_uri + '?error=authorization_error' +
        '&error_description=' + encodeURIComponent(err.graphQLErrors[0].message));
    });
  }

  submit = () => {
    const { data } = this.props;

    if (data.application.scope && data.application.scope !== null) {
      this.authorizeMarketplace();
    } else {
      this.authorizeApplication();
    }
  };

  renderPermissions = (scope, disabled) => {

    const scopeSettings = {
      messages: {
        typename: ['MESSAGE'],
        read: {
          requirements: []
        },
        delete: {
          requirements: [
            {
              type: 'messages',
              action: 'read'
            }
          ],
          info: __('Only moments can be deleted for now')
        }
      },
      reports: {
        typename: ['REPORT'],
        read: {
          requirements: []
        },
        createUpdate: {
          requirements: [
            {
              type: 'reports',
              action: 'read'
            }
          ]
        },
        delete: {
          requirements: [
            {
              type: 'reports',
              action: 'read'
            }
          ]
        }
      },
      entities: {
        typename: ['ENTITY', 'GROUP'],
        read: {
          requirements: []
        },
        createUpdate: {
          requirements: [
            {
              type: 'entities',
              action: 'read'
            }
          ]
        },
        delete: {
          requirements: [
            {
              type: 'entities',
              action: 'read'
            }
          ],
          info: __('Only groups can be deleted')
        }
      },
      dashboard: {
        typename: ['DASHBOARD'],
        read: {
          requirements: [
            {
              type: 'entities',
              action: 'read'
            }
          ]
        }
      },
      integration: {
        typename: ['INTEGRATION'],
        read: {
          requirements: []
        }
      },
      payments: {
        typename: ['PAYMENT'],
        read: {
          requirements: []
        }
      },
      accounts: {
        typename: ['ACCOUNT'],
        read: {
          requirements: []
        },
        createUpdate: {
          requirements: [
            {
              type: 'accounts',
              action: 'read'
            }
          ]
        },
        delete: {
          requirements: [
            {
              type: 'accounts',
              action: 'read'
            }
          ]
        }
      },
      organization: {
        typename: ['ORGANIZATION'],
        update: {
          requirements: []
        }
      },
      forms: {
        typename: ['FORM'],
        read: {
          requirements: []
        },
        createUpdate: {
          requirements: [
            {
              type: 'forms',
              action: 'read'
            }
          ]
        },
        delete: {
          requirements: [
            {
              type: 'forms',
              action: 'read'
            }
          ]
        }
      },
      links: {
        typename: ['LINK'],
        read: {
          requirements: []
        },
        createUpdate: {
          requirements: [
            {
              type: 'links',
              action: 'read'
            }
          ]
        },
        delete: {
          requirements: [
            {
              type: 'links',
              action: 'read'
            }
          ]
        }
      },
    };
    const features = this.props.organization && this.props.organization.features;
    const confCharge = this.props.organization && this.props.organization.confCharge;


    const options = [
      { title: __('Organization messages and moments'), field: 'messages', icon: 'envelope' },
      { title: __('Reports'), field: 'reports', icon: 'list layout' },
      { title: __('Entities, Channels and Groups'), field: 'entities', icon: 'users' },
      { title: __('Organization dashboard'), field: 'dashboard', icon: 'tachometer alt' },
      { title: __('Integrations'), field: 'integration', icon: 'puzzle piece' },
      { title: __('Payments: Balance and Charges Dashboards'), field: 'payments', icon: 'donate' },
      { title: __('Payments: Banks and Withdrawals'), field: 'accounts', icon: 'university' },
      { title: __('Forms'), field: 'forms', icon: 'poll h' },
      { title: __('Organization configurations'), field: 'organization', icon: 'cog' },
      { title: __('Accesses'), field: 'links', icon: 'box open full' }
    ];

    return (
      <Grid divided="vertically" style={{ margin: '0px 28px' }} >
        <Grid.Row columns={4} style={{ padding: 0 }}>
          <Grid.Column width={10} />
          {
            [__('Visualizing'), __('Adding and Editing'), __('Deleting')].map((title, key) =>
              <Grid.Column textAlign="center" verticalAlign="bottom" width={2} key={key}>
                <h4>{title}</h4>
              </Grid.Column>)
          }
        </Grid.Row>
        {
          options.map((item, k) =>
            <Grid.Row columns={4} key={k} style={{ padding: 0 }}>
              <Grid.Column textAlign="left" width={10}>
                <p style={{ paddingLeft: '2em' }}><Icon name={item.icon} /> {item.title}</p>
              </Grid.Column>
              {
                ['read', ['createUpdate', 'update'], 'delete'].map((key) => {
                  let action = typeof key === 'string' ? key : key[0];

                  if (typeof key !== 'string' && !scopeSettings[item.field][action]) {
                    action = key[1];
                  }
                  return (
                    !scopeSettings[item.field][action] ?
                      (
                        <Grid.Column textAlign="center" width={2}>
                          {
                            disabled ?
                              (
                                <Popup
                                  trigger={<Icon name={'minus filled circle'} />}
                                  content={__('Unavailable')}
                                  hideOnScroll
                                  basic
                                />
                              )
                              :
                              null
                          }
                        </Grid.Column>
                      ) :
                      (<Grid.Column textAlign="center" width={2} key={action}>
                        {
                          disabled ?
                            (
                              scope[item.field][action] ?
                                (
                                  <Popup
                                    trigger={<Icon style={{ color: '#099268' }} name={'check filled circle'} />}
                                    content={__('Enabled')}
                                    hideOnScroll
                                    basic
                                  />
                                )
                                :
                                (
                                  <Popup
                                    trigger={<Icon style={{ color: '#F03E3E' }} name={'times fille  d circle'} />}
                                    content={__('Disabled')}
                                    hideOnScroll
                                    basic
                                  />
                                )
                            )
                            :
                            (
                              scopeSettings[item.field][action].info ?
                                <Popup
                                  trigger={<ColoredCheckbox
                                    checked={scope[item.field][action]}
                                    data-action={`select-${item.field}`}
                                    data-value={action}
                                  />}
                                  content={scopeSettings[item.field][action].info}
                                />
                                :
                                <ColoredCheckbox
                                  checked={scope[item.field][action]}
                                  data-action={`select-${item.field}`}
                                  data-value={action}
                                />
                            )
                        }
                      </Grid.Column>)
                  );
                })
              }
            </Grid.Row>)
        }

      </Grid>
    );
  }

  renderTerm = (termLinks, application)=>{
    if(termLinks){
    return (
      <center>
        <p style={styles.observation}>
          {__('By continuing you agree to the ')}   
          {termLinks.map((link) => 
          <Link target="_blank" to={link.link}>{__("%s Application Integration Addendum",application.name)} </Link>
    )}   

        </p>
        <br/>
      </center>
    )}
  }

  getOrganizationOptions = (data)=>{
    if((data.application.scope && data.application.scope === null)) return false

    let availableOrganizations =  data.viewer.organizations.nodes.filter(this.userHasAdminEntity)
    if(!availableOrganizations.length) return false

    availableOrganizations = availableOrganizations.filter((o) => this.organizationHasApplication(o.id, data.application))
    if(!availableOrganizations.length) return false
    
    return availableOrganizations.map(o => ({
        text: o.fullname,
        value: o.id,
        image: <Avatar avatar spaced="right" src={o.logo && o.logo.uri} alt={o.fullname} />
      }));
  }

  organizationHasApplication = (organizationId, application) => {
    return !application.organization.nodes.some((o) => o.organizationId == organizationId )
  }

  userHasAdminEntity = (element) => element.myEntities.totalCount > 0

  render() {
    const { loading, disabled, organization } = this.state;
    const { data } = this.props;
    const term = data.application && data.application.term
    const termLinks = term && utils.hasJsonStructure(term.links) ? JSON.parse(term.links) : null;
    let organizationOptions = [];
    let selectOrganization;
    let requireOrganization = disabled;

    if (loading || data.loading || !data.viewer) {
      return (<div id="AuthThirdParty" style={styles.loading}>
        <Dimmer active inverted>
          <Loader size="huge">{__('Loading')}</Loader>
        </Dimmer>
        {this.props.children}
      </div>);
    }

    if (!loading && !data.loading && !data.application) {
      return (<div style={styles.noPermission}>
        <Message negative>
          <Message.Header>{__('Application not homologated')}</Message.Header>
          <p>{__('Your application needs to be homologated by the ClassApp team')}</p>
        </Message>
      </div>);
    }


    organizationOptions = this.getOrganizationOptions(data);

    if (organizationOptions.length) {
      selectOrganization = (<div style={{ marginBottom: '24px' }}>
        <Dropdown
          style={styles.dropdown} placeholder={__('Choose the organization')}
          selection options={organizationOptions} value={organization}
          onChange={(e, { value }) => this.onOrganizationChange(value)}
        />
      </div>);
    }

    if (data.viewer && !data.viewer.confirmEmail && !data.viewer.confirmPhone) {
      return (<div style={styles.noPermission}>
        <Message negative>
          <Message.Header>
            {
              !data.viewer.confirmEmail && !data.viewer.confirmPhone && data.viewer.email && data.viewer.phone ?
                __('Email and Phone are not confirmed')
                :
                (
                  !data.viewer.confirmEmail && data.viewer.email ?
                    __('Email is not confirmed')
                    :
                    __('Phone is not confirmed')
                )
            }
          </Message.Header>
          <p>
            {
              !data.viewer.confirmEmail && !data.viewer.confirmPhone && data.viewer.email && data.viewer.phone ?
                __('Before authorize an application one of your addresses needs to be confirmed')
                :
                __('Before authorize an application your address needs to be confirmed')
            }
          </p>
        </Message>
      </div>);
    }

    if (!organizationOptions.length && (data.application.scope && data.application.scope === 'marketplace')) {
      return (<div style={styles.noPermission}>
        <Message negative>
          <Message.Header>{__('Not authorized')}</Message.Header>
          <p>{__('Your user is not allowed to this authorization')}</p>
        </Message>
      </div>
      );
    }

    return (
      <div id="AuthThirdParty" className="ui compact center aligned very padded segment" style={styles.box}>
        <center>
          <Image src="https://driwojsy9347i.cloudfront.net/logo/ClassApp-LOGO-SIMBOLO_azul.png" style={styles.logo} />
        </center>
        <h2 style={styles.header}>{__('Allow %s to access your account informations in ClassApp?', data.application.name)}
          <Popup
            trigger={<Icon color="grey" name="info circle" size="small" />}
            header={data.application.author}
            content={data.application.description}
          />
        </h2>
        {this.renderTerm(termLinks, data.application)}
        {selectOrganization}

        <Button.Group vertical>
          <Button style={styles.buttonSubmit} disabled={requireOrganization} type="submit" onClick={this.handleSubmit}>{__('Allow')}</Button>
          <Button style={styles.buttonRejection} type="submit" onClick={this.handleRejection}>{__('Cancel')}</Button>
        </Button.Group>

        {this.renderPermissions(utils.parseScopeToForm(this.props.data.application.entityScope), true)}
        <center>
          <p style={styles.observation}>
            {__('This permission allows the application to access and use your ClassApp account data. The application\'s author and you are responsible to managing this access.')}
          </p>
        </center>
        {this.props.children}
      </div>
    );
  }
}
