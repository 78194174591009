/* Extern */
import React from 'react';
import { inject, observer } from 'mobx-react';
import cookie from 'react-cookie';
import {
  Form,
  Divider,
  Icon,
  Grid
} from 'semantic-ui-react';
import gql from 'graphql-tag';

import Timer from '../../components/Timer';

import Button from '../../components/ui/Button';

import CodeChecker from './CodeChecker';

import * as utils from '../../utils';
import { sendGoogleAnalyticsEvent } from '../../lib/analytics';
import i18n, { __ } from '../../i18n';

const resendCodeMap = {
  email: __('We sent you an email with a link. Check your inbox for instructions'),
  phone: __('We sent you an SMS with a link. Check your messages for instructions')
};

@inject('store', 'api', 'client') @observer
export default class Login extends React.Component {
  static fetchData({ store }) {
    store.app.title = __('Login');
  }

  constructor(props) {
    super(props);

    this.state = {
      password: '',
      code: '',
      hidden: 'eye',
      recoverPasswordSent: false,
      loginCodeSent: !props.store.auth.awaitingSendCode,
      error: false,
      disabled: true,
      loginType: 'password'
    };
  }

  componentDidMount() {
    const { user } = this.props.store.auth;

    if (!user) this.props.router.push('/auth');

    if (user && !user.hasPassword) {
      if (!this.state.loginCodeSent) this.sendLoginCode();
    }
  }

  switchType = () => {
    const hidden = this.state.hidden === 'eye' ? 'eye slash' : 'eye';
    this.setState({ hidden });
  };

  sendLoginCode = async () => {
    const { type } = this.props.store.auth;

    const params = { [type]: this.props.store.auth.user[type] };

    await this.props.client.mutate({
      mutation: gql`mutation findUser($email: String, $phone: String, $invite: Boolean, $isNewCode: Boolean) {
          findUser(email: $email, phone: $phone, invite: $invite, source: WEB, isNewCode: $isNewCode) {
            __typename
          }
        }`,
      variables: {
        ...params,
        invite: true,
        isNewCode: true
      },
      options: {
        fetchPolicy: 'network-only'
      }
    });

    this.setState({ loginCodeSent: true });
  }

  handleSubmit = (e) => {
    e.preventDefault();

    if (!navigator.onLine) {
      this.props.store.snackbar = { active: true, message: __('No connection available'), dismissAfter: 4000 };
      return;
    }

    this.validate();
  }

  validate = async () => {
    const password = this.state.password;

    this.setState({ error: !password });

    if (!password) {
      this.props.store.snackbar = { active: true, message: __('Password must not be empty'), success: false };
      return;
    }

    this.submit();
  }

  postLogin = async () => {
    const { user, type } = this.props.store.auth;

    const params = { password: this.state.password, [type]: user[type] };

    return this.props.client.mutate({
      mutation: gql`mutation passwordAuthenticate($passwordAuthenticateMutation: PasswordAuthenticateInput!) {
          passwordAuthenticate(input: $passwordAuthenticateMutation) {
            user {
              language
              isMaster
              id: dbId
              oauthProvider {
                accessToken
                refreshToken
              }
            }
            clientMutationId
          }
        }`,
      variables: {
        passwordAuthenticateMutation: {
          ...params
        }
      }
    });
  }

  recoverPassword = async () => {
    const { store } = this.props;
    const { auth: { user, type } } = store;

    if (!navigator.onLine) {
      this.props.store.snackbar = { active: true, message: __('No connection available'), dismissAfter: 4000 };
      return;
    }

    try {
      const res = await this.props.client.mutate({
        mutation: gql`mutation findUser($email: String, $phone: String) {
          findUser (email: $email, phone: $phone, recoverPassword: true, isNewCode: true, source: WEB) {
            ... on User {
              id: dbId
              fullname
            }
          }
        }`,
        variables: {
          [type]: user[type]
        }
      });
      this.setState({ recoverPasswordSent: true });

      const userId = res.data && res.data.findUser && res.data.findUser.id;

      sendGoogleAnalyticsEvent({
        name: '(Auth) Recover',
        category:  'User clicked in Recover Password',
        label: `UserID: ${userId}`,
      }, { store });
    } catch (err) {
      this.props.store.snackbar = { active: true, message: utils.handleError(err.graphQLErrors[0]), success: false }; this.setState({ recoverPasswordSent: false });
    }
  }

  submit = async () => {
    const { store, location, router } = this.props;
    const { type } = store.auth;

    try {
      const data = await this.postLogin();
      const { oauthProvider, language, id: userId } = data.data.passwordAuthenticate.user;
      const { accessToken, refreshToken } = oauthProvider;

      if (data) {
        cookie.save('access_token', accessToken, { path: '/', domain: store.app.host.replace(/www|beta|https:\/\/|:5400/g, ''), maxAge: 60 * 60 * 24 * 7 });
        cookie.save('refresh_token', refreshToken, { path: '/', domain: store.app.host.replace(/www|beta|https:\/\/|:5400/g, ''), maxAge: 60 * 60 * 24 * 7 });
        store.access_token = accessToken;
        store.refresh_token = refreshToken;
        this.props.api.access_token = accessToken;
      }

      if (language) {
        cookie.save('lang', language, { path: '/', maxAge: 60 * 60 * 24 * 7 });
        store.app.locale = language;
        i18n.locale = store.app.locale;
      }

      await this.props.client.mutate({
        mutation: gql`mutation updateUser($updateUserMutation: UpdateUserInput!) {
          updateUser(input: $updateUserMutation) {
            clientMutationId
          }
        }`,
        variables: {
          updateUserMutation: {
            tzOffset: -(new Date().getTimezoneOffset()),
            isNewCode: true
          }
        }
      });

      // clean history session, a logged user cannot go back to login
      store.history = [];

      sendGoogleAnalyticsEvent({
        name: '(Auth) Login',
        category: `User with ${type.toUpperCase()} and PASSWORD`,
        label: `UserID: ${userId}`,
      }, { store });

      if (location.query.next && location.query.next.startsWith('http')) {
        utils.redirectUrl(location.query.next);
      } else {
        router.push(location.query.next || '/');
      }
    } catch (err) {
      if (!this.props.store.snackbar.active) this.props.store.snackbar = { active: true, message: utils.handleError(err.graphQLErrors[0]), success: false };
    }
  }

  render() {
    const { user, type } = this.props.store.auth;
    const { hidden, error, recoverPasswordSent, password, disabled, loginType, loginCodeSent } = this.state;

    if (!user) return null;
    return (
      <div id="AuthLogin">
        {(user && !user.hasPassword) || loginType === 'code' ? (
          <CodeChecker {...this.props} />
        ) : (
          <div>
            <h1 className="label" style={{ ontSize: '28px', textAlign: 'left' }}>{__('Make Login') /*English: Login, PTBR: Faça seu login*/}</h1>
            {user && user.methodType === 'trial-login' ? (<p style={{ marginBottom: 15, fontSize: 15, color: '#6C6C6C', textAlign: 'left' }}>{__('We have identified that you already have an account registered with ClassApp with this data.')}</p>) : ''}
            <Divider hidden />
            <Form autoComplete="off">
              <div>
                <p className="label" style={{ textAlign: 'left', padding: '5px 0px', margin: '0px' }}>
                  {__('Password')}
                </p>
              </div>
              <Form.Input
                error={error}
                name="password"
                placeholder={__('Enter your password here')}
                type={hidden === 'eye' ? 'password' : 'text'}
                onKeyPress={e => e.key === 'Enter' && this.handleSubmit(e)}
                size="large"
                icon={<Icon name={hidden} onClick={this.switchType} style={{ cursor: 'pointer' }} />}
                onChange={(e, data) => this.setState({ password: data.value, disabled: data.value === '' })}
                value={password}
                autoFocus
              />
              {recoverPasswordSent && (
                <Grid.Row>
                  <Grid.Column style={{ border: '1px solid #e9ecef', borderRadius: '5px', padding: '16px', margin: '16px 0px' }}>
                    {resendCodeMap[this.props.store.auth.type]}
                  </Grid.Column>
                </Grid.Row>
              )}
              <Divider hidden />
              <Grid.Row>
                <Grid.Column>
                  <Button primary full round text={__('Enter')} onClick={this.handleSubmit} disabled={disabled} style={{ padding: '16px 24px' }} />
                  {type === 'email' &&
                    <Button
                      full
                      round
                      transparent
                      text={__('Enter with code')}
                      style={{ marginTop: 20, padding: '16px 24px' }}
                      onClick={() => {
                        this.setState({ loginType: 'code' })
                        if (!loginCodeSent) this.sendLoginCode();
                      }}
                    />}
                </Grid.Column>
                <Divider hidden />
                <Grid.Column textAlign="center">
                  <div id="passwordRecoverLink" onClick={recoverPasswordSent || this.recoverPassword} style={{ color: '#616161', cursor: recoverPasswordSent ? 'default' : 'pointer' }}>
                    {recoverPasswordSent ? <Timer
                      interval={30}
                      content={__('Wait')}
                      postContent={__('to request again')}
                      textColor="#212529"
                      onFinishTimer={() => this.setState({ recoverPasswordSent: false })}
                    /> : __('I forgot my password')}
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Form>
          </div>
        )}
        {user && user.hasPassword && type === 'email' && loginType === 'code' &&
          <span
            onClick={() => this.setState({ loginType: 'password' })}
            style={{ color: '#616161', cursor: 'pointer', fontSize: '16px' }}
          >
            <Divider hidden />
            <div><i className={'icon key'} /> {__('Enter with password')}</div>
          </span>
        }
        {this.props.children}
      </div>
    );
  }
}
