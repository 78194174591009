import React from 'react';
import { inject, observer } from 'mobx-react';
import cookie from 'react-cookie';
import { Form, Popup, Divider, Container, Image, Icon } from 'semantic-ui-react';
import gql from 'graphql-tag';
import { Link } from 'react-router';

import Button from '../../components/ui/Button';
import BetaModal from '../../components/ui/Modal';

import i18n, { __ } from '../../i18n';
import * as utils from '../../utils';
import { sendGoogleAnalyticsEvent } from '../../lib/analytics';

const style = {
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: '370px',
    gap: '42px',
    margin: '64px 30px 30px 30px',
  }
};
@inject('store', 'api', 'client') @observer
export default class CreatePassword extends React.Component {
  static fetchData({ store }) {
    store.app.title = __('Create Password');
  }

  constructor(props) {
    super(props);

    this.state = {
      password: '',
      passwordType: 'password',
      confirmPassword: '',
      confirmPasswordType: 'password',
      confirmPasswordError: false,
      passwordError: false,
      disabled: true,
      methodType: '',
      type: props.store.auth.user && props.store.auth.user.email ? 'email' : 'phone',
    };
  }

  componentDidMount() {
    const { user } = this.props.store.auth;

    if (!user) return this.props.router.push('/auth');

    this.setState({ methodType: user.methodType });
  }

  switchType = () => {
    const value = this.state.passwordType === 'password' ? 'text' : 'password';
    this.setState({ passwordType: value });
  };

  switchConfirmType = () => {
    const value = this.state.confirmPasswordType === 'password' ? 'text' : 'password';
    this.setState({ confirmPasswordType: value });
  }

  handleSubmit = async (e) => {
    const { password, methodType } = this.state;

    if (password === '' || password.trim().length < 5) {
      this.props.store.snackbar = { active: true, message: __('Password need more than 5 characters'), success: false };
      this.setState({ passwordError: true });
      return;
    }

    if (this.state.password !== this.state.confirmPassword) {
      this.props.store.snackbar = { active: true, message: __('Password confirmation is incorrect'), success: false };
      this.setState({ confirmPasswordError: true, passwordError: false });
      return;
    }

    e.preventDefault();
    if (methodType === 'signup') {
      this.createUserSubmit();
      return;
    }

    await this.updatePasswordSubmit();
  }

  validate = () => {
    const { password, confirmPassword } = this.state;

    this.setState({ disabled: password === '' || confirmPassword === '' });
  }

  createUserSubmit = async () => {
    const { store, router } = this.props;
    const { fullname, email, phone, language, code } = store.auth.user;
    const { user } = store.auth;
    const { password } = this.state;

    this.setState({ loading: true });
    try {
      const data = await this.props.client.mutate({
        mutation: gql`mutation createUser($createUserMutation: CreateUserInput!) {
            createUser(input: $createUserMutation) {
              user {
                oauthProvider {
                  accessToken
                  refreshToken
                }
              }
              clientMutationId
            }
          }`,
        variables: {
          createUserMutation: {
            fullname,
            email,
            phone,
            language,
            password,
            addresses: [{ code }],
            tzOffset: -(new Date().getTimezoneOffset()),
            isNewCode: true,
          }
        }
      });
      this.setState({ loading: false });

      const { oauthProvider } = data.data.createUser.user;
      const { accessToken, refreshToken } = oauthProvider;

      cookie.save('access_token', accessToken, { path: '/', domain: store.app.host.replace(/www|beta|https:\/\/|:5400/g, ''), maxAge: 60 * 60 * 24 * 7 });
      cookie.save('refresh_token', refreshToken, { path: '/', domain: store.app.host.replace(/www|beta|https:\/\/|:5400/g, ''), maxAge: 60 * 60 * 24 * 7 });
      store.access_token = accessToken;
      store.refresh_token = refreshToken;
      this.props.api.access_token = accessToken;
      store.auth.user.isNewUser = true;

      if (language) {
        cookie.save('lang', language, { path: '/', maxAge: 60 * 60 * 24 * 7 });
        store.app.locale = language;
        i18n.locale = store.app.locale;
      }

      if (user.type) {
        sendGoogleAnalyticsEvent({
          name: '(Auth) SignUp',
          category: `Create User with ${user.type.toUpperCase()} address`,
        }, { store });
      }

      router.push(this.props.location.query.next || '/');
    } catch (err) {
      this.setState({ loading: false });
      this.props.store.snackbar = { active: true, message: utils.handleError(err.graphQLErrors[0]), success: false };
    }
  };

  updatePasswordSubmit = async () => {
    const { store } = this.props;
    const { accessToken, refreshToken, language } = this.props.store.auth.user;
    const { password } = this.state;

    this.setState({ loading: true });

    const updatePasswordInput = {
      newPassword: password && password.trim(),
      loginMethod: 'PASSWORD',
    };

    try {
      cookie.save('access_token', accessToken, { path: '/', domain: store.app.host.replace(/www|beta|https:\/\/|:5400/g, ''), maxAge: 60 * 60 * 24 * 7 });
      cookie.save('refresh_token', refreshToken, { path: '/', domain: store.app.host.replace(/www|beta|https:\/\/|:5400/g, ''), maxAge: 60 * 60 * 24 * 7 });
      store.access_token = accessToken;
      store.refresh_token = refreshToken;
      this.props.api.access_token = accessToken;

      if (language) {
        cookie.save('lang', language, { path: '/', maxAge: 60 * 60 * 24 * 7 });
        store.app.locale = language;
        i18n.locale = store.app.locale;
      }

      await this.props.client.mutate({
        mutation: gql`mutation updateUser($updateUserMutation: UpdateUserInput!) {
            updateUser(input: $updateUserMutation) {
              clientMutationId
            }
          }`,
        variables: {
          updateUserMutation: {
            tzOffset: -(new Date().getTimezoneOffset()),
            isNewCode: true,
            ...updatePasswordInput,
          }
        }
      });
      this.setState({ loading: false });

      this.props.router.push(this.props.location.query.next || '/');
      this.createPasswordModal();
    } catch (err) {
      this.setState({ loading: false });
      this.props.store.snackbar = { active: true, message: utils.handleError(err.graphQLErrors[0]), success: false };
    }
  };

  createPasswordModal = () => {
    const { type } = this.state;

    return (this.props.store.appends.push(
      <BetaModal
        id="CreatedPasswordModal"
        onClose={() => this.props.store.appends.pop()}
        style={{ width: 'auto' }}
        content={
          <div style={style.content}>
            <Image src={utils.asset('/images/createdPassword.png')} style={{ maxWidth: '225px' }} centered />
            <h1 className="label" style={{ fontSize: '28px', textAlign: 'center' }}>
              {__('Password successfully registered ✅')}
            </h1>
            <p className="label subtitle" style={{ color: 'rgba(0, 0, 0, 0.6)', textAlign: 'center' }}>
              {__(`Next time you log in, simply enter your ${type === 'email' ? 'e-mail address' : 'cellphone number'} and new password.`)}
            </p>
            <Button
              primary
              full
              round
              text={__('Ok')}
              data-action="ok"
              style={{ padding: '16px 24px' }}
              onClick={() => this.props.store.appends.pop()}
            />
          </div>
        }
      />
    ));
  }

  render() {
    const {
      passwordType,
      loading,
      password,
      confirmPassword,
      confirmPasswordType,
      confirmPasswordError,
      passwordError,
      disabled,
      methodType
    } = this.state;

    const entityType = this.props.store.auth.user && this.props.store.auth.user.entityType && this.props.store.auth.user.entityType.join('+');

    const passwordIcon = passwordType === 'password' ? 'eye' : 'eye slash';
    const confirmPasswordIcon = confirmPasswordType === 'password' ? 'eye' : 'eye slash';

    return (
      <div id="AuthCreatePassword">
        <h1 className="label" style={{ fontSize: '28px', textAlign: 'left' }}>{__('Register a password')}</h1>
        <Divider hidden />
        <Form>
          <Container textAlign="left">
            <p className="label" style={{ textAlign: 'left', padding: '5px 0px', margin: '0px' }}>
              {__('New password')}
              <Popup
                trigger={<Icon name="info circle" style={{ marginLeft: '0px' }} />}
                content={__('Your password need minimum 5 characters')}
              />
            </p>
            <Form.Input
              name="password"
              placeholder={__('Enter your password here')}
              type={passwordType}
              size="large"
              error={passwordError}
              value={password}
              icon={<Icon name={passwordIcon} style={{ cursor: 'pointer' }} onClick={this.switchType} />}
              onChange={(e, { value }) => this.setState({ password: value }, () => this.validate())}
            />
            <Divider hidden />
            <p className="label" style={{ textAlign: 'left', padding: '5px 0px', margin: '0px' }}>
              {__('Confirm new password')}
            </p>
            <Form.Input
              name="confirmPassword"
              placeholder={__('Enter your password here')}
              type={confirmPasswordType}
              size="large"
              error={confirmPasswordError}
              value={confirmPassword}
              onKeyPress={e => e.key === 'Enter' && this.handleSubmit(e)}
              icon={<Icon name={confirmPasswordIcon} style={{ cursor: 'pointer' }} onClick={this.switchConfirmType} />}
              onChange={(e, { value }) => this.setState({ confirmPassword: value }, () => this.validate())}
            />
          </Container>
          <Divider hidden />
          <Button primary full round text={__('Confirm')} style={{ padding: '16px 24px' }} onClick={this.handleSubmit} loading={loading} disabled={disabled || loading} />
          <Divider hidden />
          {methodType === 'signup' ? <div>
            <p className="label" style={{ color: '#999999', cursor: 'default', fontSize: '14px', marginBottom: '0' }}>{__('By creating an account, you agree to our')}</p>
            <Link className="label" style={{ color: '#0069FF' }} to={`/terms?type=${entityType}`} target="_blank">{__('Terms of Use and Privacy')}</Link>
          </div> : null}
        </Form>
        {this.props.children}
      </div>
    );
  }
}
