import React from 'react';
import { inject, observer } from 'mobx-react';
import { Divider } from 'semantic-ui-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import { __ } from '../../i18n';
import * as utils from '../../utils';

const recoverMessage = {
  email: __('A link was sent to your email address, check your inbox for instructions'),
  phone: __('A link was sent by SMS, check your messages for instructions')
};

@inject('store')
@graphql(gql`mutation findUser($email: String, $phone: String) {
  findUser (email: $email, phone: $phone, recoverPassword: true, isNewCode: true, source: WEB) {
    ... on User {
      id: dbId
      fullname
    }
  }
}`)
@observer
export default class PasswordRecover extends React.Component {
  componentDidMount() {
    const { store, mutate } = this.props;

    const { user, type } = store.auth;
    const address = user && type && user[type];

    store.app.title = __('Password Recover');

    if (!address) return null;

    try {
      mutate({
        variables: {
          [type]: address
        }
      });
    } catch (err) {
      this.props.store.snackbar = { active: true, message: utils.handleError(err.graphQLErrors[0]), success: false };
    }
  }

  render() {
    const { type } = this.props.store.auth;
    const message = type && recoverMessage[type];

    return (
      <div id="AuthPasswordRecover">
        <h1>{__('Recover Your Password')}</h1>
        <Divider hidden />
        <p>{message}</p>
        {this.props.children}
      </div>
    );
  }
}
