import React from 'react';
import { inject, observer } from 'mobx-react';
import { Form, Popup, Divider, Container } from 'semantic-ui-react';
import gql from 'graphql-tag';
import cookie from 'react-cookie';

import Button from '../../components/ui/Button';

import i18n, { __ } from '../../i18n';

import * as utils from '../../utils';
import { sendGoogleAnalyticsEvent } from '../../lib/analytics';
import Timer from '../../components/Timer';

@inject('store', 'api', 'client') @observer
export default class CodeChecker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: false,
      code: '',
      waiting: false,
      disabled: true
    };
  }

  componentDidMount() {
    const { query } = this.props.location;

    if (query.address_code) {
      this.setState({ code: query.address_code }, () => {
        this.submit();
      });
    }

    if (!this.props.store.auth.user || (!this.props.store.auth.user.methodType === 'login' && !this.props.store.auth.user.methodType === 'trial-login')) this.resendCode(true);
  }

  handleSubmit = (e) => {
    e.preventDefault();

    this.validate();
  }

  validate = () => {
    const { code } = this.state;
    const valid = code && code.length === 6;

    if (!valid) {
      this.setState({ error: true });
    } else {
      this.setState({ error: false }, () => {
        this.submit();
      });
    }
  }

  authorizationAddressCode = async (client, code, address) => {
    try {
      const data = await this.props.client.mutate({
        mutation: gql`mutation CodeSignupQuery($code: String!, $address: String!) {
      findAddress(code:$code, address: $address){
        id: dbId
        code
        type
        address
        entitiesType
      }
    }`,
        variables: {
          code,
          address
        }
      });

      const { store } = this.props;

      if (data.errors) {
        store.snackbar = { active: true, message: utils.handleError(data.errors[0], 'codesignup'), success: false };
      } else if (store.auth.address) {
        store.auth.user = {
          address: store.auth.address,
          type: store.auth.type,
          methodType: 'signup',
          code,
          entityType: data.data.findAddress.entitiesType
        };

        this.props.router.push('/auth/signup');
      } else {
        const { type } = data.data.node;
        store.auth.user = {
          address: data.data.node.address,
          type: type.toLowerCase(),
          methodType: 'signup',
          code
        };

        this.props.router.push('/auth/signup');
      }
    } catch (err) {
      this.props.store.snackbar = { active: true, message: utils.handleError(err.graphQLErrors[0], 'codesignup'), success: false, dismissAfter: 6000 };
    }
  }

  authorizationUserCode = async () => {
    try {
      const data = await this.props.client.mutate({
        mutation: gql`mutation codeAuthenticate($codeAuthenticateMutation: CodeAuthenticateInput!) {
          codeAuthenticate(input: $codeAuthenticateMutation) {
            user {
              language
              hasPassword
              isMaster
              id: dbId
              oauthProvider {
                accessToken
                refreshToken
              }
            }
            clientMutationId
          }
        }`,
        variables: {
          codeAuthenticateMutation: {
            code: this.state.code
          }
        }
      });

      const { oauthProvider, language, id: userId } = data.data.codeAuthenticate.user;
      const { accessToken, refreshToken } = oauthProvider;

      const { store, location, router } = this.props;
      const { user, type } = store.auth;

      if (user) {
        user.accessToken = accessToken;
        user.refreshToken = refreshToken;
        user.language = language;

        if (!user.hasPassword) {
          router.push('/auth/createPassword' + (location.search || ''));
          return;
        }
      }

      if (data) {
        cookie.save('access_token', accessToken, { path: '/', domain: store.app.host.replace(/www|beta|https:\/\/|:5400/g, ''), maxAge: 60 * 60 * 24 * 7 });
        cookie.save('refresh_token', refreshToken, { path: '/', domain: store.app.host.replace(/www|beta|https:\/\/|:5400/g, ''), maxAge: 60 * 60 * 24 * 7 });
        store.access_token = accessToken;
        store.refresh_token = refreshToken;
        this.props.api.access_token = accessToken;
      }

      if (language) {
        cookie.save('lang', language, { path: '/', maxAge: 60 * 60 * 24 * 7 });
        store.app.locale = language;
        i18n.locale = store.app.locale;
      }

      await this.props.client.mutate({
        mutation: gql`mutation updateUser($updateUserMutation: UpdateUserInput!) {
        updateUser(input: $updateUserMutation) {
          clientMutationId
        }
      }`,
        variables: {
          updateUserMutation: {
            tzOffset: -(new Date().getTimezoneOffset())
          }
        }
      });

      // clean history session, a logged user cannot go back to login
      store.history = [];

      if (location.query.next && location.query.next.startsWith('http')) {
        utils.redirectUrl(location.query.next);
      } else {
        router.push(location.query.next || '/');
      }

      sendGoogleAnalyticsEvent({
        name: '(Auth) Login',
        category: `User with ${type.toUpperCase()} and NO PASSWORD`,
        label: `UserID: ${userId}`,
      }, { store });
    } catch (err) {
      this.props.store.snackbar = { active: true, message: utils.handleError(err.graphQLErrors[0]), success: false };
    }
  }

  submit = () => {
    const { code } = this.state;
    const { client, store } = this.props;
    return store.auth.user && (store.auth.user.methodType === 'login' || store.auth.user.methodType === 'trial-login') ? this.authorizationUserCode() : this.authorizationAddressCode(client, code, store.auth.address);
  };

  resendCode = async (firstTry = false) => {
    const { store } = this.props;
    const params = {};

    if (store.auth.user && (store.auth.user.methodType === 'login' || store.auth.user.methodType === 'trial-login')) {
      params[store.auth.type] = store.auth.type === 'email' ? store.auth.user.email : store.auth.user.phone;
    } else {
      params[store.auth.type] = store.auth.address;
    }

    try {
      const res = await this.props.client.mutate({
        mutation: gql`mutation findUser($email: String, $phone: String) {
          findUser(email: $email, phone: $phone, invite: true, source: WEB, isNewCode: true) {
            __typename
            ... on User {
              id: dbId
              fullname
              isMaster
            }
          }
        }`,
        variables: {
          ...params
        }
      });
      this.setState({ waiting: true });

      if (!firstTry) {
        this.props.store.snackbar = { active: true, message: __('The code was sent successfully'), success: true };
      }

      const { id: userId } = res.data && res.data.findUser;

      if (!firstTry) {
        sendGoogleAnalyticsEvent({
          name: '(Auth) Recover',
          category: `User RESEND code by ${store.auth.type.toUpperCase()}`,
          label: `UserID: ${userId}`,
        }, { store });
      }
    } catch (err) {
      this.props.store.snackbar = { active: true, message: utils.handleError(err.graphQLErrors[0]), success: false };
    }
  };

  render() {
    const { code, error, waiting, disabled } = this.state;
    const { type } = this.props.store.auth;
    const { auth } = this.props.store;

    if ((auth.email === '' || auth.phone === '') && !auth.address) this.props.router.push('/auth');

    const address = auth[type] || auth.address;
    const formattedAddress = type === 'email' ? address : utils.formatPhone(address, true);

    return (
      <div id="CodeChecker" style={{ textAlign: 'left' }}>
        <h1 className="label" style={{ fontSize: '28px' }}>{type === 'phone' ? __('Verify your phone') : __('Verify your email')}</h1>
        {auth.user && auth.user.methodType === 'trial-login' ? (<p style={{ marginBottom: 36, fontSize: 15, color: '#6C6C6C' }}>{__('We have identified that you already have an account registered with ClassApp with this data.')}</p>) : ''}
        <Divider hidden />
        <p className="label subtitle" style={{ color: 'rgba(0, 0, 0, 0.6)' }}>{type === 'phone' ? __('Enter the code sent to the phone') : __('Enter the code sent to the email')}</p>
        <p className="label subtitle" style={{ color: 'black' }}>{formattedAddress}</p>
        <Form >
          <Form.Input
            name="code"
            placeholder="* * * * * *"
            value={code}
            error={error}
            style={{ height: 50, marginTop: 36 }}
            onKeyPress={e => e.key === 'Enter' && code.length === 6 && this.handleSubmit(e)}
            className="code-checker"
            action={error && <Popup
              type="popup"
              trigger={<Button icon="exclamation triangle" basic color="red" />}
              content={__('The code must have 6 digits')}
            />}
            maxLength={6}
            onChange={(e, data) => this.setState({ code: data.value, disabled: data.value.length !== 6 })}
          />
          <Button primary full round disabled={disabled} text={__('Continue')} style={{ marginTop: 20, padding: '16px 24px' }} onClick={this.handleSubmit} />
          <Button
            round
            transparent
            disabled={waiting}
            onClick={() => this.resendCode()}
            full
            style={{
              marginTop: 20,
              padding: '16px 24px',
              border: waiting ? 'none' : null
            }}
            text={waiting ?
              <Timer
                interval={30}
                content={__('Wait')}
                postContent={__('to request again')}
                textColor="#212529"
                onFinishTimer={() => this.setState({ waiting: false })}
              />
              : __('I didn\'t receive the code')}
          />
        </Form>
      </div>
    );
  }
}
