import React from 'react';
import { inject, observer } from 'mobx-react';
import { Button, Divider } from 'semantic-ui-react';
import { __ } from '../../i18n';

const styles = {
  button: {
    height: '50px',
    width: '100%',
    backgroundColor: 'transparent',
    border: '1px solid #e9ecef',
    margin: '8px 0px'
  },
  primary: {
    backgroundColor: '#0069ff',
    color: '#fff'
  }
};

@inject('store') @observer
export default class InboundingSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'no',
      wasAnswered: false
    };
  }

  componentDidMount() {
    const { auth } = this.props.store;
    if (!auth.prospectType || !auth.contact) this.props.router.push('/auth');
  }

  handleButtonInstitutionHasClassApp = (e, type) => {
    e.preventDefault();
    if (type == 'no') {
      this.props.store.simulation.user = {
        type: this.props.store.auth.prospectType,
        [this.props.store.auth.prospectType]: this.props.store.auth.contact,
        phoneFormatted: this.props.store.auth.phoneFormatted
      };

      this.props.router.push('/trial');
    }

    this.setState({ type, wasAnswered: true });
  }

  render() {
    const { type, wasAnswered } = this.state;

    return (
      <div id="AuthInboundingSelector">
        <h1 className="label title" style={{ textAlign: 'left' }}>
          {type == 'no' ?
            __('We couldn\'t find your contact in any school. 😕') :
            __('You need an invitation from your institution to access ClassApp')
          }
        </h1>
        <Divider hidden />
        <p style={!wasAnswered ? { textAlign: 'left' } : null}>
          {(type == 'no' || !wasAnswered) ?
            __('Does your institute already use ClassApp?') :
            __('We suggest that you contact it')
          }
        </p>

        {!wasAnswered && (
          <div>
            <Button data-action="no" circular onClick={e => this.handleButtonInstitutionHasClassApp(e, 'no')} style={{ ...styles.button, ...styles.primary }}>{__('No, but I\'d like to know more!')}</Button>
            <Button data-action="yes" circular type="submit" onClick={e => this.handleButtonInstitutionHasClassApp(e, 'yes')} style={styles.button}>{__('Yes')}</Button>
          </div>
        )}

        {wasAnswered && (
          <Button circular type="submit" onClick={() => this.props.router.push('/auth')} style={styles.button}>{__('Try with another contact')}</Button>
        )}

      </div>
    );
  }
}
